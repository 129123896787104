import {
  AccountInfo,
  SiteAuth as SiteAuthBase,
  SiteAuthConfig
} from '@friss/identity';
import Permission from './permission';
import { embeddedVisualizerAuth } from '@friss/embedded-visualizer';
import permissionRoleMap from './permissionRoleMap';

export default class SiteAuth extends SiteAuthBase {
  constructor(config: SiteAuthConfig) {
    super(config);

    this.notifyEmbeddedVisualizers();
  }

  protected async notifyEmbeddedVisualizers() {
    if (this.getActiveAccount()) {
      embeddedVisualizerAuth.setAccessToken(
        (await this.getToken()) ?? undefined
      );
    } else {
      embeddedVisualizerAuth.setAccessToken();
    }
  }

  public setActiveAccount(account: AccountInfo | null): void {
    super.setActiveAccount(account);
    this.notifyEmbeddedVisualizers();
  }

  public hasPermission(permission: Permission): boolean {
    const profile = this.getActiveAccount()?.idTokenClaims;

    const isExternalRolesUsed = profile?.use_external_roles ?? false;
    const tokenRoles = profile?.fcm_role;
    if (!isExternalRolesUsed && !tokenRoles) {
      // If no fcm_roles provided in token, allow everything.
      // We are relying on an external system to provide these roles.
      // If an external system does not provide the roles or there is no external system,
      // all behavior is allowed.
      return true;
    }

    const rolesToCheck = permissionRoleMap[permission];
    return rolesToCheck !== undefined && this.userHasOneOfRoles(rolesToCheck);
  }

  public userHasRole(role: string): boolean {
    const tokenRoles = this.getActiveAccount()?.idTokenClaims?.fcm_role ?? [];
    return (
      tokenRoles === role ||
      (Array.isArray(tokenRoles) && tokenRoles.includes(role))
    );
  }

  public userHasOneOfRoles(roles: string[]): boolean {
    for (const role of roles) {
      if (this.userHasRole(role)) {
        return true;
      }
    }
    return false;
  }
}
