enum ErrorCode {
  SendingFeedbackFailed = 'SendingFeedbackFailed',
  NoClientIdProvided = 'NoClientIdProvided',
  NoRootObjectIdProvided = 'NoRootObjectIdProvided',
  FetchingClaimDataFailed = 'FetchingClaimDataFailed',
  FetchingUnderwritingDataFailed = 'FetchingUnderwritingDataFailed',
  UrlNotFound = 'UrlNotFound',
  Unauthorized = 'Unauthorized',
  Unknown = 'Unknown'
}

export default ErrorCode;
