import { defineStore } from 'pinia';

export type LayoutId = string | null;

export const useLayoutStore = defineStore('layout', {
  state: () => {
    return {
      layout: null as LayoutId
    };
  },

  actions: {
    setLayout(layoutName: LayoutId) {
      this.layout = layoutName;
    }
  }
});
