import siteLocale from '@/localization';
import ErrorCode from '@/models/ErrorCode';
import { RouteLocationNormalized } from 'vue-router';

const HomeView = () => import('@/views/home/index.vue');
const ErrorView = () => import('@/views/error/index.vue');
const SessionExpired = () => import('@/views/sessionExpired/index.vue');

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      getTitle: () => siteLocale.locale.t('claim'),
      layout: 'AppLayoutOpenInFSC'
    }
  },
  {
    path: '/error/',
    name: 'error',
    component: ErrorView,
    meta: {
      getTitle: () => siteLocale.locale.t('error'),
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/sessionexpired/',
    name: 'sessionexpired',
    component: SessionExpired,
    meta: {
      getTitle: () => siteLocale.locale.t('sessionExpired'),
      layout: 'AppLayoutDefault'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: (to: RouteLocationNormalized) => {
      return {
        name: 'error',
        query: {
          locale: to.query.locale ?? siteLocale.locale.language,
          code: ErrorCode.UrlNotFound
        }
      };
    }
  }
];
