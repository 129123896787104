import defaultConfig from './defaultConfig';

declare global {
  interface Window {
    __RUNTIME_CONFIG__: Record<string, string>;
  }
}

const parseRawConfig = function (rawConfig: Record<string, string>) {
  const config: Record<string, unknown> = {};
  Object.keys(rawConfig).forEach((key) => {
    const valRaw = rawConfig[key];

    if (/^\$\{.*\}$/.exec(valRaw)) {
      // If string matches pattern ${variable_name}, don't parse it.
      // It means it was not substituted with the environment variable.
      // This happens when envsubst is not executed(e.g. in dev mode).
      return;
    }

    if (valRaw === '') {
      // If it is an empty string, it means it has not been set in the environment.
      return;
    }

    try {
      // Try parse value as a json. E.g. if it is a list or an object.
      // Otherwise, use it as is.
      config[key] = JSON.parse(valRaw);
    } catch {
      config[key] = valRaw;
    }
  });
  return config;
};

const parseRuntimeConfig = function () {
  if (typeof window !== 'undefined' && window.__RUNTIME_CONFIG__) {
    const runtimeConfigRaw = window.__RUNTIME_CONFIG__;
    return parseRawConfig(runtimeConfigRaw);
  }
  return {};
};

const parseEnvConfig = function () {
  return parseRawConfig(process.env as Record<string, string>);
};

// Injected runtime configuration can be overwritten by the environment variables.
const runtimeConfig = {
  ...defaultConfig,
  ...parseRuntimeConfig(),
  ...parseEnvConfig()
};

export default runtimeConfig;
