import { RouteLocationNormalized } from 'vue-router';
import siteLocale from '@/localization';

export default async function (to: RouteLocationNormalized) {
  if (to.query.locale && siteLocale.locale.language === to.query.locale) {
    return true;
  } else {
    const localeToSet =
      (to.query.locale as string) ?? siteLocale.locale.language;
    await siteLocale.setLanguage(localeToSet);

    return {
      ...to,
      query: { ...to.query, locale: siteLocale.locale.language }
    };
  }
}
