import Permission from './permission';

/**
 * Maps app permissions to Identity roles that allow them.
 * If one of the roles is present in the token,
 * the permission will be allowed.
 */
const permissionRoleMap: Record<Permission, string[]> = {
  [Permission.FeedbackSubmissionAllowed]: [
    'Standard User Feedback',
    'Claim Manager',
    'Administrator'
  ],
  [Permission.OpenClaimInFSCAllowed]: [
    'Standard User Link',
    'Claim Manager',
    'Administrator'
  ],
  [Permission.AccessAllowed]: [
    'Claim Manager',
    'Administrator',
    'Standard User',
    'Standard User Link',
    'Standard User Feedback'
  ]
};

export default permissionRoleMap;
