export interface AppConfig {
  VUE_APP_MOCK_API: boolean;
  VUE_APP_API_URL: string | undefined;
  VUE_APP_IDS: string | undefined;
  VUE_APP_SUPPORTED_LANGUAGES: string[];
  VUE_APP_SUPPORT_EMAIL: string;
}

const defaultConfig: AppConfig = {
  VUE_APP_MOCK_API: false,
  VUE_APP_API_URL: undefined,
  VUE_APP_IDS: undefined,
  VUE_APP_SUPPORTED_LANGUAGES: ['en', 'de', 'nl', 'fr', 'es'],
  VUE_APP_SUPPORT_EMAIL: 'helpdesk@friss.freshservice.com'
};

export default defaultConfig;
