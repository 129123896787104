export default function () {
  const clientIdKey = 'frissClientId';
  const urlParams = new URLSearchParams(window.location.search);
  const clientIdFromURL = urlParams.get('clientId');

  if (clientIdFromURL) {
    localStorage.setItem(clientIdKey, clientIdFromURL);
    return clientIdFromURL;
  } else {
    const clientId = localStorage.getItem(clientIdKey);
    if (clientId) {
      return clientId;
    }
    return null;
  }
}
