import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  Router
} from 'vue-router';
import mainAuth from '@/auth';
import ErrorCode from '@/models/ErrorCode';
import handlePageTitles from './handlePageTitles';
import handleLocale from './handleLocale';
import siteLocale from '@/localization';
import routes from './routes';
import handleLayout from './handleLayout';
import getClientId from '@/helpers/getClientId';

interface SiteRouter extends Router {
  raiseError: (errorCode: ErrorCode) => Promise<void>;
}

export const makeRouter = function () {
  const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL)
  }) as SiteRouter;

  router.raiseError = async function (errorCode: ErrorCode) {
    await router.push({
      name: `error`,
      query: {
        code: errorCode,
        locale: siteLocale.locale.language
      }
    });
  };

  const handleAuthRequirements = async function (to: RouteLocationNormalized) {
    if (
      to.meta?.requiresAuth === true &&
      getClientId() === null &&
      to.name !== 'error'
    ) {
      await router.raiseError(ErrorCode.NoClientIdProvided);
      return false;
    } else {
      return true;
    }
  };

  router.beforeEach(handleAuthRequirements);
  mainAuth.useRouter(router);
  router.beforeEach(handleLayout);
  router.beforeResolve(handleLocale);
  router.afterEach(handlePageTitles);

  return router;
};

export default makeRouter();
