import { RouteLocationNormalized } from 'vue-router';
import siteLocale from '@/localization';

export default function (to: RouteLocationNormalized) {
  const pageTitlePrefix = 'FRISS ' + siteLocale.locale.t('screeningCenter');

  const titleGetter = to.meta?.getTitle as () => string;
  const pageTitle = titleGetter ? ` - ${titleGetter()}` : '';
  document.title = `${pageTitlePrefix}${pageTitle}`;
  return true;
}
