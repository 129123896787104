import runtimeConfig from '@/config';
import getClientId from '@/helpers/getClientId';
import {
  SiteAuthConfig,
  InteractionType,
  LogLevel,
  ProtocolMode
} from '@friss/identity';

const config: SiteAuthConfig = {
  auth: {
    clientId: getClientId()!,
    authority: runtimeConfig.VUE_APP_IDS,
    knownAuthorities: [runtimeConfig.VUE_APP_IDS!],
    protocolMode: ProtocolMode.OIDC,
    navigateToLoginRequestUrl: true,
    redirectUri: '/',
    postLogoutRedirectUri: '/sessionexpired/',
    scopes: ['openid', 'identity', 'legacy', 'screening'],
    logoutAfterInactivity: true,
    logoutAfterInactivityTimeoutMs: 30 * 60 * 1000 // 30 minutes
  },
  routing: {
    interactionType: InteractionType.Redirect
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Warning
    },
    allowRedirectInIframe: true
  }
};

export default config;
