import { uiLibLocale } from '@friss/ui-components';
import { embeddedVisualizerLocale } from '@friss/embedded-visualizer';
import { SiteLocale, resourcesToBackend } from '@friss/localization';
import en from '@/assets/locales/en.json';
import config from '@/config';

const resourceProvider = resourcesToBackend(
  (
    language: string,
    _namespace: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (error: unknown, resources: any) => void
  ) => {
    import(
      /* webpackChunkName: "locale-[request]" */
      `./assets/locales/${language}.json`
    )
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }
);

class ScreeningCenterLocale extends SiteLocale {
  async setLanguage(language: string) {
    await embeddedVisualizerLocale.setLanguage(language);
    await uiLibLocale.setLanguage(language);
    return await super.setLanguage(language);
  }
}

const screeningCenterLocale = new ScreeningCenterLocale(
  'en',
  config.VUE_APP_SUPPORTED_LANGUAGES,
  resourceProvider,
  en
);

export default screeningCenterLocale;
export const _testing = {
  ScreeningCenterLocale,
  resourceProvider
};
