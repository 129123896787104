import 'reflect-metadata';
import App from './App.vue';
import { createApp } from 'vue';
import router from './router';
import { createPinia } from 'pinia';
import { FrissUiLibPlugin } from '@friss/ui-components';
import '@friss/design-system/fonts.css';
import '@friss/ui-components/styles';
import '@friss/embedded-visualizer/styles';
import './scss/tailwind.css';
import './scss/global.scss';
import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp(App).use(createPinia()).use(router).use(FrissUiLibPlugin);

router.isReady().then(() => {
  app.mount('#app');
});
